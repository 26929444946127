import React, { FC } from "react";
import { OfferTemplate } from "@templates/OfferTemplate";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import styled from "styled-components";
import { breakpoints } from "@theme/breakpoints";
import { UniversalSection } from "@components/molecules/UniversalSection";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import { Routes } from "@routes";
import { Button } from "@components/atoms/Button";
import WhatWeCanDoMore from "@components/organisms/WhatWeCanDoMore";

const StyledButton = styled(Button)`
  width: fit-content;
  text-decoration: none;
  margin: 100px auto 0;

  ${breakpoints.tabletMax`
    margin-top: 80px;
  `}

  ${breakpoints.phoneMax`
    margin-top: 60px;
  `};
`;

const Investment: FC<Props> = ({ data }) => {
  const { t } = useTranslation("offer");
  const { t: ct } = useTranslation("common");

  return (
    <OfferTemplate
      title={ct("title-offer-investment-website")}
      description={ct("description-offer-investment-website")}
      heroTitle={t("investment-website.hero.title")}
      heroDescription={t("investment-website.hero.description", {
        returnObjects: true,
      })}
      heroImage={data.heroImage.childImageSharp.gatsbyImageData}
    >
      <UniversalSection
        title={t("investment-website.sections.0.title")}
        description={[t("investment-website.sections.0.description")]}
        images={[data.firstSectionImage.childImageSharp.gatsbyImageData]}
        fromRight
        btnText={t("see-realizations")}
        btnTo={Routes.RealisedProjects}
      />
      <UniversalSection
        title={t("investment-website.sections.1.title")}
        description={[t("investment-website.sections.1.description")]}
        images={[data.secondSectionImage.childImageSharp.gatsbyImageData]}
        btnTo={Routes.RealisedProjects}
        fromRight
        btnText={t("see-realizations")}
      />
      <UniversalSection
        title={t("investment-website.sections.2.title")}
        description={t("investment-website.sections.2.description", {
          returnObjects: true,
        })}
        images={[data.thirdSectionImage.childImageSharp.gatsbyImageData]}
        btnTo={Routes.RealisedProjects}
        btnText={t("see-realizations")}
      />
      <UniversalSection
        title={t("investment-website.sections.3.title")}
        description={t("investment-website.sections.3.description", {
          returnObjects: true,
        })}
        images={[data.fourthSectionImage.childImageSharp.gatsbyImageData]}
        btnTo={Routes.RealisedProjects}
        btnText={t("see-realizations")}
        fromRight
      />
      <UniversalSection
        title={t("investment-website.sections.4.title")}
        description={t("investment-website.sections.4.description", {
          returnObjects: true,
        })}
        images={[data.fifthSectionImage.childImageSharp.gatsbyImageData]}
        btnTo={Routes.RealisedProjects}
        btnText={t("see-realizations")}
      />

      <UniversalSection
        title={t("investment-website.sections.5.title")}
        description={t("investment-website.sections.5.description", {
          returnObjects: true,
        })}
        images={[data.sixthSectionImage.childImageSharp.gatsbyImageData]}
        btnTo={Routes.RealisedProjects}
        btnText={t("see-realizations")}
        fromRight
      />

      <UniversalSection
        title={t("investment-website.sections.6.title")}
        description={t("investment-website.sections.6.description", {
          returnObjects: true,
        })}
        images={data.seventhSectionImages.nodes.map(
          (exteriorImage) => exteriorImage.childImageSharp.gatsbyImageData
        )}
        btnTo={Routes.RealisedProjects}
        btnText={t("see-realizations")}
      />

      <StyledButton
        secondary
        forwardedAs={Link as any}
        to={Routes.RealisedProjects}
      >
        {t("see-rendpro-realizations")}
      </StyledButton>

      <WhatWeCanDoMore exclude={0} />
    </OfferTemplate>
  );
};

interface Props {
  data: {
    heroImage: ChildImageSharp;
    firstSectionImage: ChildImageSharp;
    secondSectionImage: ChildImageSharp;
    thirdSectionImage: ChildImageSharp;
    fourthSectionImage: ChildImageSharp;
    fifthSectionImage: ChildImageSharp;
    sixthSectionImage: ChildImageSharp;
    seventhSectionImages: {
      nodes: ChildImageSharp[];
    };
  };
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    heroImage: file(name: { eq: "offer_investment-website-hero" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }

    firstSectionImage: file(name: { eq: "offer_investment-website_1" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }

    secondSectionImage: file(name: { eq: "offer_investment-website_2" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }

    thirdSectionImage: file(name: { eq: "offer_investment-website_3" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }

    fourthSectionImage: file(name: { eq: "offer_investment-website_4" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }

    fifthSectionImage: file(name: { eq: "offer_investment-website_5" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }

    sixthSectionImage: file(name: { eq: "offer_investment-website_6" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }

    seventhSectionImages: allFile(
      filter: { name: { regex: "/exterior_/" } }
      sort: { fields: [name], order: [ASC] }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 80)
        }
      }
    }
  }
`;

export default Investment;
